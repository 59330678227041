import React, { useEffect, useLayoutEffect, useState } from "react";
// import "../assets/css/home.scss";
import {
  IconBook,
  IconBrandHipchat,
  IconChevronDown,
  IconChevronRight,
  IconDialpad,
  IconHelp,
  IconLogout,
  IconMenu2,
  IconPageBreak,
  IconQuestionMark,
  IconSettings,
  IconSmartHome,
  IconUser,
  IconUserPin,
  IconUserPlus,
  IconX,
} from "@tabler/icons-react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import "../assets/css/demo.css";
import "../assets/css/user.css";
import Logo from "../assets/img/logo.svg";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";

import avatar1 from "../assets/img/avatars/1.png";
import { baseUrl } from "../config/axios";
import { useAuth } from "../context/UseContext";

export default function NewSidebar() {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const [activeLink, setActiveLink] = useState("1");
  const [openMenu, setOpenMenu] = useState(false);
  const [opensupport, setOpensupport] = useState(false);

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const [visible, setVisible] = useState(false);
  const onClose = () => {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    if (window.innerWidth < 1296) {
      setVisible(false);
    } else if (window.innerWidth >= 1296) {
      setVisible(true);
    }
  }, [window.innerWidth]);

  const { user, userLogout } = useAuth();

  return (
    <div className="light-style layout-navbar-fixed layout-menu-fixed layout-compact">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <aside
            id="layout-menu"
            class={`${
              !visible ? "open_close_sidebar" : ""
            } layout-menu menu-vertical menu bg-menu-theme scroller `}
            data-bg-class="bg-menu-theme"
          >
            <div class="app-brand demo">
              <a href="/" class="app-brand-link">
                <span class="app-brand-logo demo dashboard-logo">
                  <img className="logo-styl panel-logo" src={Logo} />
                </span>
              </a>
              <a
                href="javascript:void(0);"
                class="layout-menu-toggle menu-link text-large ms-auto"
              >
                {window.innerWidth < 1296 ? <IconX onClick={onClose} /> : null}{" "}
              </a>
            </div>

            <div class="menu-inner-shadow"></div>
            <ul class="menu-inner py-1 ps">
              <li
                class={`${
                  window.location.pathname == "/dashboard" ? "active" : ""
                } menu-item`}
              >
                <Link
                  to="/dashboard"
                  class="menu-link"
                  onClick={() => {
                    setActiveLink("/dashboard");
                  }}
                >
                  <IconSmartHome className="menu-icon" />
                  <div data-i18n="Page 1">Dashboard</div>
                </Link>
              </li>
              <li
                class={`${
                  window.location.pathname == "/account" ? "active" : ""
                } menu-item`}
              >
                <Link to="/account" class="menu-link">
                  <IconUserPin className="menu-icon" />
                  <div data-i18n="Page 1">Profile</div>
                </Link>
              </li>
              <li
                class={`${
                  window.location.pathname == "/chat" ? "active" : ""
                } menu-item`}
              >
                <Link to="/chat" class="menu-link">
                  <IconBrandHipchat className="menu-icon" />
                  <div data-i18n="Page 1">Talk to Kriss</div>
                </Link>
              </li>
              <li
                class={`${
                  window.location.pathname == "/pages" ? "active" : ""
                } menu-item`}
              >
                <Link to="/pages" class="menu-link">
                  <IconBook className="menu-icon" />
                  <div data-i18n="Page 1">Pages</div>
                </Link>
              </li>

              {/* <li
                class={`${window.location.pathname == "/usage" ? "active" : ""
                  } menu-item`}
              >
                <Link to="/usage" class="menu-link">
                  <IconChartPie className="menu-icon" />
                  <div data-i18n="Page 1">Usage</div>
                </Link>
              </li> */}

              <li
                class={`${
                  window.location.pathname == "/billing-plans" ? "active" : ""
                } menu-item`}
              >
                <Link to="/billing-plans" class="menu-link">
                  <IconPageBreak className="menu-icon" />
                  <div data-i18n="Page 1">Billing</div>
                </Link>
              </li>

              {/* <li class="menu-item">
                <a
                  href="javascript:void(0)"
                  class="menu-link"
                  onClick={() => {
                    if (openMenu) {
                      setOpenMenu(false);
                    } else {
                      setOpenMenu(true);
                    }
                  }}
                >
                  <IconSettings className="menu-icon" />
                  <div className="d-flex justify-content-between w-100">
                    <div data-i18n="Page 2">Settings</div>
                    {!openMenu ? (
                      <IconChevronRight className="mx-2" width={18} />
                    ) : (
                      <IconChevronDown className="mx-2" width={18} />
                    )}
                  </div>
                </a>
                <ul
                  className={`${
                    openMenu ? "" : "d-none"
                  } menu-inner py-1 ps mx-4`}
                >
                  <li
                    className={`${
                      window.location.pathname == "/project" ? "active" : ""
                    } menu-item`}
                    style={{ width: "14rem" }}
                  >
                    <Link
                      to="/project"
                      class="menu-link"
                      onClick={() => {
                        setActiveLink("/prject");
                      }}
                    >
                      <IconBrandOffice className="menu-icon" />
                      <div data-i18n="Page 2">Project</div>
                    </Link>
                  </li>
                  <li
                    className={`${
                      window.location.pathname == "/data" ? "active" : ""
                    } menu-item`}
                    style={{ width: "14rem" }}
                  >
                    <Link
                      to="/data"
                      class="menu-link"
                      onClick={() => {
                        setActiveLink("/data");
                      }}
                    >
                      <IconDatabase className="menu-icon" />

                      <div data-i18n="Page 2">Data</div>
                    </Link>
                  </li>
                  <li
                    className={`${
                      window.location.pathname == "/chatbot-settings"
                        ? "active"
                        : ""
                    } menu-item`}
                    style={{ width: "14rem" }}
                  >
                    <Link
                      to="/chatbot-settings"
                      class="menu-link"
                      onClick={() => {
                        setActiveLink("/chatbot-settings");
                      }}
                    >
                      <IconMessage2 className="menu-icon" />
                      <div data-i18n="Page 2">Chatbot Setting</div>
                    </Link>
                  </li>
                  <li
                    className={`${
                      window.location.pathname == "/sharing" ? "active" : ""
                    } menu-item`}
                    style={{ width: "14rem" }}
                  >
                    <Link
                      to="/sharing"
                      class="menu-link"
                      onClick={() => {
                        setActiveLink("/sharing");
                      }}
                    >
                      <IconShare className="menu-icon" />
                      <div data-i18n="Page 2">Sharing</div>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li
                class={`${
                  window.location.pathname == "/settings" ? "active" : ""
                } menu-item`}
              >
                <Link to="/settings" class="menu-link">
                  <IconSettings className="menu-icon" />
                  <div data-i18n="Page 1">Settings</div>
                </Link>
              </li>

              {/* <li
                class={`${window.location.pathname == "/adds" ? "active" : ""
                  } menu-item`}
              >
                <Link to="/adds" class="menu-link">
                  <IconAd className="menu-icon" />
                  <div data-i18n="Page 1">Add On</div>
                </Link>
              </li> */}

              <li class="menu-item">
                <a
                  href="javascript:void(0)"
                  class="menu-link"
                  onClick={() => {
                    if (opensupport) {
                      setOpensupport(false);
                    } else {
                      setOpensupport(true);
                    }
                  }}
                >
                  <IconHelp className="menu-icon" />
                  <div className="d-flex justify-content-between w-100">
                    <div data-i18n="Page 2">Support</div>
                    {!opensupport ? (
                      <IconChevronRight className="mx-2" width={18} />
                    ) : (
                      <IconChevronDown className="mx-2" width={18} />
                    )}
                  </div>
                </a>
                <ul
                  className={`${
                    opensupport ? "" : "d-none"
                  } menu-inner py-1 ps mx-4`}
                >
                  <li
                    className={`${
                      window.location.pathname == "/faq" ? "active" : ""
                    } menu-item`}
                    style={{ width: "14rem" }}
                  >
                    <Link
                      to="/faq"
                      class="menu-link"
                      onClick={() => {
                        setActiveLink("/faq");
                      }}
                    >
                      <IconQuestionMark className="menu-icon" />
                      <div data-i18n="Page 2">FAQ's</div>
                    </Link>
                  </li>
                  <li
                    className={`${
                      window.location.pathname == "/contact-us" ? "active" : ""
                    } menu-item`}
                    style={{ width: "14rem" }}
                  >
                    <Link
                      to="/contact-us"
                      class="menu-link"
                      onClick={() => {
                        setActiveLink("/contact-us");
                      }}
                    >
                      <IconDialpad className="menu-icon" />

                      <div data-i18n="Page 2">Contact us</div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                class={`${
                  window.location.pathname == "/onboard" ? "active" : ""
                } menu-item`}
              >
                <Link to="/onboard" class="menu-link">
                  <IconUserPlus className="menu-icon" />
                  <div data-i18n="Page 1">Account Setup</div>
                </Link>
              </li>
              <li
                class={`${
                  window.location.pathname == "/pricing" ? "active" : ""
                } menu-item`}
              >
                <Link to="/pricing" class="menu-link">
                  <IconUser className="menu-icon" />
                  <div data-i18n="Page 1">Plans</div>
                </Link>
              </li>
            </ul>
          </aside>
        </div>

        <div className="layout-page">
          <nav
            className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar"
          >
            <div
              style={{ zIndex: "11111111" }}
              className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none "
            >
              <a
                className="nav-item nav-link px-0 me-xl-4"
                href="javascript:void(0)"
              >
                <IconMenu2 onClick={onClose} />
              </a>
            </div>
            <div
              className="navbar-nav-right d-flex align-items-center"
              id="navbar-collapse"
            >
              {/* <div class="navbar-nav align-items-center">
                
                <div class="nav-item navbar-search-wrapper mb-0">
                  <a
                    class="nav-item nav-link search-toggler d-flex align-items-center px-0"
                    href="javascript:void(0);"
                  >
                    <IconSearch className="" />
                    <span class="d-none d-md-inline-block text-muted">
                      Search (Ctrl+/)
                    </span>
                  </a>
                </div>
              </div> */}
              <ul className="navbar-nav flex-row align-items-center ms-auto make-top">
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <a
                    className="nav-link dropdown-toggle hide-arrow"
                    data-bs-toggle="dropdown"
                  >
                    <div className="avatar">
                      <img
                        src={
                          user?.profilePic
                            ? baseUrl + user?.profilePic
                            : avatar1
                        }
                        alt
                        className="h-auto rounded-circle"
                      />
                    </div>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar">
                              <img
                                src={
                                  user?.profilePic
                                    ? baseUrl + user?.profilePic
                                    : avatar1
                                }
                                alt
                                className="h-auto rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-medium d-block">
                              {user?.name}
                            </span>
                            {/* <small className="text-muted">Admin</small> */}
                          </div>
                        </div>
                      </a>
                    </li>
                    {/* <li>
                      <a className="dropdown-item" href="#">
                        <IconPassword className="me-2" size={16} />
                        <Link to="/forgot-password" className="align-middle">
                          Forgot Password
                        </Link>
                      </a>
                    </li> */}
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>

                    <li onClick={() => userLogout()}>
                      <a className="dropdown-item" href="#">
                        <IconLogout className="me-2" size={16} />
                        Log out
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
