import { Button } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileServices from "../apis/profile/ProfileServices";
import "../assets/css/planspage.css";
import mastercard from "../assets/img/icons/payments/mastercard.png";
import visacard from "../assets/img/icons/payments/visa.png";
import Logo from "../assets/img/logo.svg";
import { useAuth } from "../context/UseContext";
import { ErrorToaster, SuccessToaster } from "./Toaster";
import Spinner from "./spinner";
import SubscriptionServices from "../apis/subscription/SubscriptionServices";
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { stripeKey } from "../utils";
import Wrapper from "../wrapper/wrapper";

const StripePaymentForm = ({
  customerId,
  getCardDetail
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async (e) => {
    setIsLoading(true);
    try {
      if (!stripe || !elements) {
        return;
      }
      const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));
      if (token) {
        let obj = {
          customerId: customerId,
          cardToken: token
        };
        const { message } = await SubscriptionServices.updateCardDetail(obj);
        SuccessToaster(message)
        getCardDetail()
      }
      if (error) {
        ErrorToaster(error)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-12">
          <label
            className="form-label w-100"
            for="multiStepsCard"
          >
            Card Number
          </label>
          <div className="input-group input-group-merge">
            <CardNumberElement id="card-element-number" className="form-control multi-steps-card" />
            <span
              className="input-group-text cursor-pointer"
              id="multiStepsCardImg"
            >
              <span className="card-type"></span>
            </span>
          </div>
        </div>
        <div className="col-md-5">
          <label className="form-label" for="multiStepsName">
            Name On Card
          </label>
          <input
            type="text"
            id="multiStepsName"
            className="form-control"
            name="multiStepsName"
            placeholder="John Doe"
          />
        </div>
        <div className="col-6 col-md-4">
          <label className="form-label" for="multiStepsExDate">
            Expiry Date
          </label>
          <CardExpiryElement id="card-element" className="form-control multi-steps-exp-date" />
        </div>
        <div className="col-6 col-md-3">
          <label className="form-label" for="multiStepsCvv">
            CVV Code
          </label>
          <div className="input-group input-group-merge">
            <CardCvcElement id="card-element" className="form-control multi-steps-cvv" />
            <span
              className="input-group-text cursor-pointer"
              id="multiStepsCvvHelp"
            >
              <i
                className="ti ti-help text-muted"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Card Verification Value"
              ></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-3">
        <button
          disabled={isLoading}
          onClick={() => handlePayment()}
          class="btn btn-primary me-sm-3 me-1"
        >
          Update
        </button>
        <button
          disabled={isLoading}
          id="close-modal"
          class="btn btn-label-danger"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Remove
        </button>
      </div>
    </>
  );
};

export default function Planpage() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = () => {
    navigate(`/pricing`);
  };

  const stripePromise = loadStripe(stripeKey);


  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  const [historyLoader, setHistoryLoader] = useState(false);
  const [cardLoader, setCardLoader] = useState(false);
  const [planLoader, setPlanLoader] = useState(false);

  const [currentPlan, setCurrentPlan] = useState();

  const [paymentHistory, setPaymentHistory] = useState([]);
  const [cardDetail, setCardDetail] = useState();

  const getCurrentPlan = async () => {
    setPlanLoader(true);
    try {
      const { data } = await ProfileServices.getCurrentPlan();
      setCurrentPlan(data?.subscriptionDetails);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setPlanLoader(false);
    }
  };

  const cancelSubscription = async () => {
    try {
      const { message } = await ProfileServices.cancelSubscription();
      SuccessToaster(message);
      navigate("/dashboard");
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getPaymentHistory = async () => {
    setHistoryLoader(true);
    try {
      const { data } = await ProfileServices.getPaymentHistory();
      setPaymentHistory(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setHistoryLoader(false);
    }
  };

  const getCardDetail = async () => {
    setCardLoader(true);
    try {
      const { result } = await SubscriptionServices.getCardDetail();
      setCardDetail(result);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setCardLoader(false);
    }
  };

  useEffect(() => {
    getCurrentPlan();
    getPaymentHistory();
    getCardDetail();
  }, []);

  return (
    <Wrapper>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="pt-6">
              {/* <div style={{ display: "none" }}>
                <h1 className="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                  {" "}
                  Subscribe{" "}
                </h1>
                <div className="mt-6 sm:px-8">
                  <div className="mt-6 bg-white sm:rounded-lg shadow-sm overflow-hidden">
                    <div className="px-6 py-4">
                      <h2 className="text-xl font-semibold text-gray-700">
                        {" "}
                        Subscription Information{" "}
                      </h2>
                      <div className="mt-6 flex items-center">
                        <span className="w-1/3 mr-10 text-gray-800 text-sm font-semibold">
                          Card
                        </span>
                        <div
                          id="card-element"
                          className="w-full bg-white border border-gray-200 p-3 rounded"
                        ></div>
                      </div>
                      <div className="mt-1 flex items-center">
                        <span className="w-1/3 mr-10">&nbsp;</span>
                        <button className="w-full text-gray-400 text-sm underline text-left active:outline-none focus:outline-none">
                          {" "}
                          Have a coupon code?{" "}
                        </button>
                      </div>
                      <div className="mt-6 flex items-center">
                        <span className="w-1/3 mr-10 text-gray-800 text-sm font-semibold">
                          Country
                        </span>
                        <select
                          name="country"
                          id="country"
                          className="form-select w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                        >
                          <option value="" disabled="disabled">
                            Select Country
                          </option>
                          {countries?.map((item, index) => (
                            <option key={index} value={item?.name}>{item?.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mt-6 flex items-center">
                        <label
                          for="address"
                          className="w-1/3 mr-10 text-gray-800 text-sm font-semibold"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          id="address"
                          placeholder="Address"
                          className="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                        />
                      </div>
                      <div className="mt-6 flex items-center">
                        <label
                          for="address2"
                          className="w-1/3 mr-10 text-gray-800 text-sm font-semibold"
                        >
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          id="address2"
                          placeholder="Address Line 2"
                          className="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                        />
                      </div>
                      <div className="mt-6 flex items-center">
                        <label
                          for="city"
                          className="w-1/3 mr-10 text-gray-800 text-sm font-semibold"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          id="city"
                          placeholder="City"
                          className="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                        />
                      </div>
                      <div className="mt-6 flex items-center">
                        <label
                          for="state"
                          className="w-1/3 mr-10 text-gray-800 text-sm font-semibold"
                        >
                          State / County
                        </label>
                        <input
                          type="text"
                          id="state"
                          placeholder="State / County"
                          className="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                        />
                      </div>
                      <div className="mt-6 flex items-center">
                        <label
                          for="postal_code"
                          className="w-1/3 mr-10 text-gray-800 text-sm font-semibold"
                        >
                          Zip / Postal Code
                        </label>
                        <input
                          type="text"
                          id="postal_code"
                          placeholder="Zip / Postal Code"
                          className="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                        />
                      </div>
                      <div className="mt-6 flex">
                        <label
                          for="extra"
                          className="w-1/3 mr-10 mt-2 text-gray-800 text-sm font-semibold"
                        >
                          Extra Billing Information
                        </label>
                        <textarea
                          id="extra"
                          rows="5"
                          placeholder="If you need to add specific contact or tax information to your receipts, like your full business name, VAT identification number, or address of record, you may add it here."
                          className="w-full bg-white border border-gray-200 px-3 py-2 rounded focus:outline-none"
                        ></textarea>
                      </div>
                    </div>
                    <div className="px-6 py-4 mt-5 bg-gray-100 bg-opacity-50 border-t border-gray-100 flex items-center">
                      <div> ... </div>
                      <button
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 ml-auto bg-custom-hex"
                        disabled="disabled"
                      >
                        {" "}
                        Subscribe{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <button className="flex items-center mt-4 px-4 sm:px-8">
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="text-gray-400 w-4 h-4"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <div className="ml-2 text-sm text-gray-600 underline">
                    {" "}
                    Select a different plan{" "}
                  </div>
                </button>
              </div> */}
              <div>
                <div>
                  <h1 className="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                    {" "}
                    Current Subscription Plan{" "}
                  </h1>
                  <div className="mt-6 sm:px-8">
                    {planLoader ? (
                      <Spinner size="large" />
                    ) : (
                      <div className="bg-white sm:rounded-lg shadow-sm">
                        {currentPlan ? (
                          <>
                            <div>
                              <div className="flex justify-between">
                                <h2 className="px-6 pt-4 text-xl font-semibold text-gray-700">
                                  {" "}
                                  {currentPlan?.name}{" "}
                                </h2>
                              </div>
                              <div className="px-6 pb-4">
                                <div className="mt-2 text-md font-semibold text-gray-700">
                                  <span>${currentPlan?.price}</span> / monthly
                                </div>
                                <div className="mt-3 max-w-xl text-sm text-gray-600">
                                  {" "}
                                  {currentPlan?.features?.subtitle}{" "}
                                </div>
                                <div className="mt-3 space-y-2">
                                  {currentPlan?.features?.keyfeatures.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <svg
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          className="text-green-400 opacity-75 w-5 h-5"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clip-rule="evenodd"
                                          ></path>
                                        </svg>
                                        <div className="ml-2 text-sm text-gray-600">
                                          {" "}
                                          {item}{" "}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="px-6 pb-4">
                              <button
                                onClick={handleClick}
                                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 mt-4 bg-custom-hex"
                              >
                                {" "}
                                Change Subscription Plan{" "}
                              </button>
                            </div>
                          </>
                        ) : (
                          <h2 className="px-6 py-4 text-xl font-semibold text-gray-700">
                            No Active Subscription Plan
                          </h2>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mt-10">
                    <h1 className="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                      Card Information{" "}
                    </h1>
                    <div className="mt-3 sm:px-8">
                      <div className="bg-white sm:rounded-lg shadow-sm overflow-hidden">
                        <div className="px-6 py-4">
                          <div class="col-md-6 mt-5 mt-md-0">
                            {cardLoader ? (
                              <Spinner size="large" />
                            ) : (
                              <Fragment>
                                <div class="added-cards">
                                  <div class="cardMaster bg-lighter p-3 rounded mb-3">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column">
                                      <div class="card-information me-2">
                                        <img
                                          class="mb-3 img-fluid"
                                          src={cardDetail?.cardDetails?.brand === 'mastercard' ? mastercard : visacard}
                                          alt="Master Card"
                                        />
                                        <div class="d-flex align-items-center mb-2 flex-wrap gap-2">
                                          {/* <p class="mb-0 me-2">{cardDetail?.email}</p> */}
                                          {/* <span class="badge bg-label-primary">
                                            Primary
                                          </span> */}
                                        </div>
                                        <span class="card-number">
                                          &#8727;&#8727;&#8727;&#8727;
                                          &#8727;&#8727;&#8727;&#8727; {cardDetail?.cardDetails?.last4}
                                        </span>
                                      </div>
                                      <div class="d-flex flex-column text-start text-lg-end">
                                        <div class="d-flex order-sm-0 order-1 mt-sm-0 mt-3">
                                          <button
                                            class="btn btn-label-primary me-2"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editCCModal"
                                          >
                                            Edit
                                          </button>
                                          {/* <button class="btn btn-label-secondary">
                                            Delete
                                          </button> */}
                                        </div>
                                        <small class="mt-sm-auto mt-2 order-sm-1 order-0">
                                          Card expires at {cardDetail?.cardDetails?.exp_month < 10 ? `0${cardDetail?.cardDetails?.exp_month}` : cardDetail?.cardDetails?.exp_month}/{String(cardDetail?.cardDetails?.exp_year).slice(-2)}
                                        </small>
                                        {/* intagrate expire date above */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="modal fade"
                                  id="editCCModal"
                                  tabindex="-1"
                                  aria-hidden="true"
                                >
                                  <div class="modal-dialog modal-dialog-centered modal-simple modal-add-new-cc">
                                    <div class="modal-content p-3 p-md-5">
                                      <div class="modal-body">
                                        <button
                                          type="button"
                                          class="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                        <div class="text-center mb-4">
                                          <h3 class="mb-2">Edit Card</h3>
                                          <p class="text-muted">
                                            Edit your saved card details
                                          </p>
                                        </div>
                                        {/* <div class="col-12">
                                            <label
                                              class="form-label w-100"
                                              for="modalEditCard"
                                            >
                                              Card Number
                                            </label>
                                            <div class="input-group input-group-merge">
                                              <input
                                                id="modalEditCard"
                                                name="modalEditCard"
                                                class="form-control credit-card-mask-edit"
                                                type="text"
                                                placeholder="4356 3215 6548 7898"
                                                value="4356 3215 6548 7898"
                                                aria-describedby="modalEditCard2"
                                              />
                                              <span
                                                class="input-group-text cursor-pointer p-1"
                                                id="modalEditCard2"
                                              >
                                                <span class="card-type-edit"></span>
                                              </span>
                                            </div>
                                          </div>
                                          <div class="col-12 col-md-6">
                                            <label
                                              class="form-label"
                                              for="modalEditName"
                                            >
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              id="modalEditName"
                                              class="form-control"
                                              placeholder="John Doe"
                                              value="John Doe"
                                            />
                                          </div>
                                          <div class="col-6 col-md-3">
                                            <label
                                              class="form-label"
                                              for="modalEditExpiryDate"
                                            >
                                              Exp. Date
                                            </label>
                                            <input
                                              type="text"
                                              id="modalEditExpiryDate"
                                              class="form-control expiry-date-mask-edit"
                                              placeholder="MM/YY"
                                              value="08/28"
                                            />
                                          </div>
                                          <div class="col-6 col-md-3">
                                            <label
                                              class="form-label"
                                              for="modalEditCvv"
                                            >
                                              CVV Code
                                            </label>
                                            <div class="input-group input-group-merge">
                                              <input
                                                type="text"
                                                id="modalEditCvv"
                                                class="form-control cvv-code-mask-edit"
                                                maxlength="3"
                                                placeholder="654"
                                                value="XXX"
                                              />
                                              <span
                                                class="input-group-text cursor-pointer"
                                                id="modalEditCvv2"
                                              >
                                                <i
                                                  class="ti ti-help text-muted"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="Card Verification Value"
                                                ></i>
                                              </span>
                                            </div>
                                          </div> */}
                                        <Elements options={options} stripe={stripePromise}>
                                          <StripePaymentForm
                                            customerId={cardDetail?.id}
                                            getCardDetail={getCardDetail}
                                          />
                                        </Elements>
                                        {/* <div class="col-12">
                                            <label class="switch">
                                              <input
                                                type="checkbox"
                                                class="switch-input"
                                              />
                                              <span class="switch-toggle-slider">
                                                <span class="switch-on"></span>
                                                <span class="switch-off"></span>
                                              </span>
                                              <span class="switch-label">
                                                Set as primary card
                                              </span>
                                            </label>
                                          </div> */}
                                        {/* <div class="col-12 text-center">
                                            <button
                                              type="submit"
                                              class="btn btn-primary me-sm-3 me-1"
                                            >
                                              Update
                                            </button>
                                            <button
                                              type="reset"
                                              class="btn btn-label-danger"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              Remove
                                            </button>
                                          </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </div>

                          {/* <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 mt-4 bg-custom-hex">
                            {" "}
                            Update Payment Information{" "}
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <h1 className="text-2xl font-semibold text-gray-700 mt-10 px-4 sm:px-8">
                    {" "}
                    Apply Coupon{" "}
                  </h1>
                  <div className="mt-3 sm:px-8">
                    <div className="bg-white sm:rounded-lg shadow-sm">
                      <div className="px-6 py-4 ">
                        <div className="mt-6 flex">
                          <label
                            for="coupon_for_existing"
                            className="w-1/3 mr-10 mt-2 text-gray-800 text-sm font-semibold"
                          >
                            Coupon
                          </label>
                          <input
                            type="text"
                            id="coupon_for_existing"
                            placeholder="Coupon"
                            className="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none"
                          />
                        </div>
                      </div>
                      <div className="px-6 py-4 mt-5 bg-gray-100 bg-opacity-50 border-t border-gray-100 text-right">
                        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-custom-hex">
                          {" "}
                          Apply{" "}
                        </button>
                      </div>
                    </div>
                  </div> */}
                  <h1 className="text-2xl font-semibold text-gray-700 mt-10 px-4 sm:px-8">
                    {" "}
                    Cancel Subscription{" "}
                  </h1>
                  <div className="mt-3 sm:px-8">
                    <div className="px-6 py-4 bg-white sm:rounded-lg shadow-sm">
                      <div className="max-w-2xl text-sm text-gray-600">
                        {" "}
                        You may cancel your subscription at any time. Once your
                        subscription has been cancelled, you will have the
                        option to resume the subscription until the end of your
                        current billing cycle.{" "}
                      </div>
                      <div className="mt-4">
                        <Button
                          disabled={currentPlan ? false : true}
                          onClick={() => cancelSubscription()}
                          className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm  focus:outline-none focus:ring active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                        >
                          Cancel Subscription
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-10">
                  <h1 className="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                    {" "}
                    Billing Information{" "}
                  </h1>
                  <div className="mt-3 sm:px-8">
                    <div className="bg-white sm:rounded-lg shadow-sm">
                      <div className="px-6 py-4 ">
                        <div className="max-w-2xl text-sm text-gray-600">
                          {" "}
                          If you need to add specific contact or tax information
                          to your receipts, like your full business name, VAT
                          identification number, or address of record, you may
                          add it here.{" "}
                        </div>
                        <div className="mt-6 flex">
                          <label
                            for="extra"
                            className="w-1/3 mr-10 mt-2 text-gray-800 text-sm font-semibold"
                          >
                            Extra Billing Information
                          </label>
                          <textarea
                            id="extra"
                            rows="3"
                            className="w-full border border-gray-200 px-3 py-2 rounded focus:outline-none"
                          ></textarea>
                        </div>
                      </div>
                      <div className="px-6 py-4 mt-5 bg-gray-100 bg-opacity-50 border-t border-gray-100 text-right">
                        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-custom-hex">
                          {" "}
                          Update{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="mt-10">
                  <h1 className="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                    {" "}
                    Receipts{" "}
                  </h1>
                  <div className="mt-3 sm:px-8">
                    {historyLoader ? (
                      <Spinner size="large" />
                    ) : (
                      <div className="bg-white sm:rounded-lg shadow-sm divide-y divide-gray-100">
                        {paymentHistory?.map((item, index) => (
                          <div key={index} className="flex px-6 py-4 row">
                            <div className="col-xxl-6 col-md-6 col-sm-12">
                              <div className="text-sm ">
                                {item?.customerId} {`(${item?.paymentInfo})`}
                              </div>
                            </div>
                            <div className="col-xxl-3 col-md-3 col-sm-12">
                              <div className="ml-10 text-sm">
                                <span>${item?.paymentAmount}</span>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-md-3 col-sm-12">
                              <div className="text-sm ">
                                {moment(item?.createdAt).format("DD MMMM YYYY")}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-backdrop fade"></div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle"></div>
      <div className="drag-target"></div>
    </Wrapper>
  );
}
