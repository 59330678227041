import { loadStripe } from "@stripe/stripe-js";
import {
  IconArrowLeft,
  IconArrowRight,
  IconChevronRight,
  IconEye,
  IconEyeOff,
  IconFileText,
  IconSmartHome,
} from "@tabler/icons-react";
import { Form } from "antd";
import Stepper from "bs-stepper";
import React, { useEffect, useRef, useState } from "react";
import { Button, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthServices from "../apis/auth/AuthServices";
import SubscriptionServices from "../apis/subscription/SubscriptionServices";
import "../assets/css/register.css";
import img1 from "../assets/img/illustrations/auth-register-multisteps-illustration.png";
import img2 from "../assets/img/illustrations/bg-shape-image-light.png";
import Logo from "../assets/img/logo.svg";
import "../assets/vendor/css/pages/page-auth.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import { useAuth } from "../context/UseContext";
import { emailRegex, stripeKey } from "../utils";
import CountryServices from "../apis/country/CountryServices";
import { CardElement, CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';

const StripePaymentForm = ({
  selectedPlan,
  email,
  handlePrevious,
  submitRegister,
  couponCode
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async (e) => {
    setIsLoading(true);
    try {
      if (!stripe || !elements) {
        return;
      }
      const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));
      if (token) {
        let obj = {
          description: "Plan Payment",
          subscriptionPlanId: selectedPlan,
          customerId: email,
          registration: true,
          cardToken: token,
          couponCode: couponCode
        };
        const { clientSecret } = await SubscriptionServices.getStripeSecretKey(obj);
        if (clientSecret) {
          submitRegister();
        }
      }
      if (error) {
        ErrorToaster(error)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <CardElement /> */}
      <div className="row g-3">
        <div className="col-md-12">
          <label
            className="form-label w-100"
            for="multiStepsCard"
          >
            Card Number
          </label>
          <div className="input-group input-group-merge">
            <CardNumberElement id="card-element-number" className="form-control multi-steps-card" />
            <span
              className="input-group-text cursor-pointer"
              id="multiStepsCardImg"
            >
              <span className="card-type"></span>
            </span>
          </div>
        </div>
        <div className="col-md-5">
          <label className="form-label" for="multiStepsName">
            Name On Card
          </label>
          <input
            type="text"
            id="multiStepsName"
            className="form-control"
            name="multiStepsName"
            placeholder="John Doe"
          />
        </div>
        <div className="col-6 col-md-4">
          <label className="form-label" for="multiStepsExDate">
            Expiry Date
          </label>
          <CardExpiryElement id="card-element" className="form-control multi-steps-exp-date" />
        </div>
        <div className="col-6 col-md-3">
          <label className="form-label" for="multiStepsCvv">
            CVV Code
          </label>
          <div className="input-group input-group-merge">
            <CardCvcElement id="card-element" className="form-control multi-steps-cvv" />
            <span
              className="input-group-text cursor-pointer"
              id="multiStepsCvvHelp"
            >
              <i
                className="ti ti-help text-muted"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Card Verification Value"
              ></i>
            </span>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-between mt-4">
        <Button
          className="btn btn-label-secondary btn-prev"
          onClick={() => handlePrevious()}
        >
          <IconArrowLeft className="me-sm-1 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button
          className="btn btn-success btn-next btn-submit"
          onClick={() => handlePayment()}
          type={"button"}
          disabled={isLoading}
        >
          <span className="align-middle d-sm-inline-block d-none">Submit </span>
        </Button>
      </div>
    </>
  );
};

export default function Register() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const stepperRef = useRef(null);

  const [selectedPlan, setSelectedPlan] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmpass, setShowConfirmpass] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const stripePromise = loadStripe(stripeKey);

  const { userLogin } = useAuth();

  const [step1Data, setStep1Data] = useState();
  const [step2Data, setStep2Data] = useState();
  const [emailExists, setEmailExists] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(0);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  const tooltipone = (
    <Tooltip id="tooltipfour">Card Verification Value</Tooltip>
  );

  useEffect(() => {
    if (!stepperRef.current) {
      stepperRef.current = new Stepper(document.querySelector("#stepper1"), {
        linear: false,
        animation: false,
      });
    }
    return () => {
      if (stepperRef.current) {
        stepperRef.current.destroy();
        stepperRef.current = null;
      }
    };
  }, []);

  const handleNext1 = async (data) => {
    const { status } = await AuthServices.checkExists({
      email: data.email
    })
    setEmailExists(status)
    if(!status){
      setStep1Data(data);
      if (stepperRef.current) {
        stepperRef.current.next();
      }
    }else{

    }
  };

  const handleNext2 = (data) => {
    setStep2Data(data);
    if (stepperRef.current) {
      stepperRef.current.next();
    }
  };

  const handlePrevious = () => {
    if (stepperRef.current) {
      stepperRef.current.previous();
    }
  };

  const [plans, setPlans] = useState([]);

  const getSubscriptionPlans = async () => {
    try {
      const { data } = await SubscriptionServices.getSubscriptionPlans()
      setPlans(data)
      setSelectedPlan(data[0]?.id);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const applyDiscount = async ()=>{
    setDiscount(0)
    const couponCheck = {
      code: couponCode
    }
    const { data } = await AuthServices.checkCoupon(couponCheck);
    if(data){
      if(data.isActive){
        let priceTotal = 0;
        if(data.allowforfees){
          priceTotal+=parseFloat(plans.find(e => e.id === selectedPlan)?.price)
        }
        if(data.allowforsetup){
          priceTotal+=parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees)
        }
        setDiscount(((parseFloat(priceTotal)/100)*data.discount_percent))
      }
    }
  }
  useEffect(()=>{
    applyDiscount()
  },[selectedPlan])

  const submitRegister = async () => {
    try {
      const accountInfo = {
        cardNumber: "",
        cardName: "",
        cardExpiryDate: "",
        cardCVVCode: "",
        subscriptionPlanId: selectedPlan,
        refferedBy: null,
        addOnId: null,
      };
      let obj = {
        name: step1Data?.name,
        email: step1Data?.email,
        password: step1Data?.password,
        cpassword: step1Data?.confirmPassword,
        signupCode: step1Data?.signupCode,
        accountInfo: accountInfo,
        country: plans[0]?.country,
        isPaid: true,
      };
      const { message, token, user } = await AuthServices.register(obj);
      SuccessToaster(message);
      navigate("/onboard");
      userLogin({ token: token, ...user });
    } catch (error) {
      ErrorToaster(error);
    }
  };
  useEffect(() => {
    getSubscriptionPlans()
  }, [])

  return (
    <div className="authentication-wrapper authentication-cover authentication-bg">
      <div className="authentication-inner row">
        <div className="d-none d-lg-flex col-lg-4 align-items-center justify-content-center p-5 auth-cover-bg-color position-relative auth-multisteps-bg-height">
          <img
            src={img1}
            alt="auth-register-multisteps"
            className="img-fluid"
            width="280"
          />

          <img
            src={img2}
            alt="auth-register-multisteps"
            className="platform-bg"
            data-app-light-img="illustrations/bg-shape-image-light.png"
          />
        </div>

        <div className="d-flex col-lg-8 align-items-center justify-content-center p-sm-5 p-3">
          <div className="w-px-700">
            <div className="register-logo">
              <a href="/" class="">
                <span class="app-brand-logo demo">
                  <img src={Logo} className="login-logo" />
                </span>
              </a>
            </div>
            <div id="stepper1" className="bs-stepper shadow-none">
              <div className="bs-stepper-header border-bottom-0">
                <div className="step" data-target="#test-l-1">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">
                      <IconSmartHome />
                    </span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Account</span>
                      <span className="bs-stepper-subtitle">
                        Account Details
                      </span>
                    </span>
                  </button>
                </div>
                {/* <div className="line">
                  <IconChevronRight size={16} />
                </div> */}
                {/* <div className="step" data-target="#test-l-2">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">
                      <IconUser />
                    </span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Business</span>
                      <span className="bs-stepper-subtitle">
                        Enter Information
                      </span>
                    </span>
                  </button>
                </div> */}
                <div className="line">
                  <IconChevronRight size={16} />
                </div>
                <div className="step" data-target="#test-l-3">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">
                      <IconFileText />
                    </span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Billing</span>
                      <span className="bs-stepper-subtitle">
                        Payment Details
                      </span>
                    </span>
                  </button>
                </div>
              </div>

              <div className="bs-stepper-content">
                <Form form={form} name="basic">
                  <div id="test-l-1" className="content">
                    <div className="content-header mb-4">
                      <h3 className="mb-1">Account Information</h3>
                      <p>Enter Your Account Details</p>
                    </div>
                    <div className="row g-3">
                      <div className="col-sm-4">
                        <label className="form-label" for="multiStepsUsername">
                          Name
                        </label>
                        <input
                          type="text"
                          name="multiStepsUsername"
                          id="multiStepsUsername"
                          className="form-control"
                          placeholder="name"
                          {...register("name", {
                            required: "Please enter name",
                          })}
                        />
                        {errors && (
                          <p className={"error"}>{errors?.name?.message}</p>
                        )}
                      </div>
                      <div className="col-sm-4">
                        <label className="form-label" for="multiStepsEmail">
                          Email
                        </label>
                        <input
                          type="email"
                          name="multiStepsEmail"
                          id="multiStepsEmail"
                          className="form-control"
                          placeholder="example@email.com"
                          aria-label="john.doe"
                          {...register("email", {
                            required: "Please enter email",
                            pattern: {
                              value: emailRegex,
                              message: "Please enter a valid email.",
                            },
                          })}
                        />
                        {errors && (
                          <p className={"error"}>{errors?.email?.message}</p>
                        )}
                        {emailExists && (
                          <p className={"error"}>Email already taken</p>
                        )}
                      </div>
                      <div className="col-sm-4">
                        <label className="form-label" for="signupCode">
                          Signup Code
                        </label>
                        <input
                          type="text"
                          name="signupCode"
                          id="signupCode"
                          className="form-control"
                          placeholder="signup code"
                          {...register("signupCode")}
                        />
                      </div>
                      <div className="col-sm-6 form-password-toggle">
                        <label className="form-label" for="multiStepsPass">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            name="currentPassword"
                            id="currentPassword"
                            type={`${!showPassword ? "password" : "text"}`}
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            {...register("password", {
                              required: "Please enter password",
                              minLength: {
                                value: 6,
                                message: "Password should be atleast 6 characters",
                              },
                            })}
                          />
                          <span className="input-group-text cursor-pointer">
                            {showPassword ? (
                              <IconEye onClick={() => setShowPassword(false)} />
                            ) : (
                              <IconEyeOff
                                onClick={() => setShowPassword(true)}
                              />
                            )}
                          </span>
                        </div>
                        {errors && (
                          <p className={"error"}>{errors?.password?.message}</p>
                        )}
                      </div>
                      <div className="col-sm-6 form-password-toggle">
                        <label
                          className="form-label"
                          for="multiStepsConfirmPass"
                        >
                          Confirm Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            type={`${!showConfirmpass ? "password" : "text"}`}
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            {...register("confirmPassword", {
                              required: "Please enter the confirm password",
                              validate: (value) =>
                                value === password.current ||
                                "Confirm password does not match.",
                            })}
                          />
                          <span className=" input-group-text cursor-pointer">
                            {showConfirmpass ? (
                              <IconEye
                                onClick={() => setShowConfirmpass(false)}
                              />
                            ) : (
                              <IconEyeOff
                                onClick={() => setShowConfirmpass(true)}
                              />
                            )}
                          </span>
                        </div>
                        {errors && (
                          <p className={"error"}>
                            {errors?.confirmPassword?.message}
                          </p>
                        )}
                      </div>

                      <div className="col-12 d-flex justify-content-between mt-4">
                        <button
                          className="btn btn-label-secondary btn-prev"
                          disabled
                        >
                          <i className="ti ti-arrow-left ti-xs me-sm-1 me-0"></i>
                          <span className="align-middle d-sm-inline-block d-none">
                            Previous
                          </span>
                        </button>
                        <Button
                          type={"button"}
                          className="btn btn-primary btn-next"
                          onClick={handleSubmit(handleNext1)}
                        >
                          <span className="align-middle d-sm-inline-block d-none me-sm-1 me-0">
                            Next
                          </span>
                          <IconArrowRight />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div id="test-l-2" className="content">
                    <div className="content-header mb-4">
                      <h3 className="mb-1">Business Information</h3>
                      <p>Enter Your Business Information</p>
                    </div>
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepsFirstName">
                          Business Name
                        </label>
                        <input
                          type="text"
                          id="multiStepsFirstName"
                          name="multiStepsFirstName"
                          className="form-control"
                          placeholder="business"
                          {...register2("businessName", {
                            required: "Please enter business name",
                          })}
                        />
                        {errors2 && (
                          <p className={"error"}>
                            {errors2?.businessName?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepsLastName">
                          Office Contact
                        </label>
                        <input
                          type="text"
                          id="multiStepsLastName"
                          name="multiStepsLastName"
                          className="form-control"
                          placeholder="202 555 0111"
                          {...register2("officePhone")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepsMobile">
                          Mobile
                        </label>
                        <div className="input-group">
                          {/* <span className="input-group-text">US (+1)</span> */}
                          <input
                            type="text"
                            id="multiStepsMobile"
                            name="multiStepsMobile"
                            className="form-control multi-steps-mobile"
                            placeholder="202 555 0111"
                            {...register2("phone")}
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-6">
                        <label className="form-label" for="multiStepsState">
                          Country
                        </label>
                        <select
                          id="multiStepsState"
                          className="select2 form-select"
                          data-allow-clear="true"
                          {...register2("country")}
                        >
                          <option value="">Select Country</option>
                          {countries?.map((item, index) => (
                            <option key={index} value={item?.name}>{item?.name}</option>
                          ))}
                        </select>
                      </div> */}
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepsPincode">
                          State
                        </label>
                        <input
                          type="text"
                          id="multiStepsPincode"
                          name="multiStepsPincode"
                          className="form-control multi-steps-pincode"
                          placeholder="State"
                          {...register2("state")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepsCity">
                          City
                        </label>
                        <input
                          type="text"
                          id="multiStepsCity"
                          className="form-control"
                          placeholder="City"
                          {...register2("city")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" for="multiStepsPincode">
                          Zip/Postal Code
                        </label>
                        <input
                          type="text"
                          id="multiStepsPincode"
                          name="multiStepsPincode"
                          className="form-control multi-steps-pincode"
                          placeholder="Postal Code"
                          maxlength="6"
                          {...register2("zipCode")}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" for="multiStepsAddress">
                          Address
                        </label>
                        <input
                          type="text"
                          id="multiStepsAddress"
                          name="multiStepsAddress"
                          className="form-control"
                          placeholder="Address"
                          {...register2("address")}
                        />
                      </div>
                      {/* <div className="col-md-12">
                        <label className="form-label" for="multiStepsArea">
                          Landmark
                        </label>
                        <input
                          type="text"
                          id="multiStepsArea"
                          name="multiStepsArea"
                          className="form-control"
                          placeholder="Area/Landmark"
                        />
                      </div> */}
                      <div className="col-12 d-flex justify-content-between mt-4">
                        <Button
                          type={"button"}
                          className="btn btn-label-secondary btn-prev"
                          onClick={handlePrevious}
                        >
                          <IconArrowLeft size={16} className=" me-sm-1 me-0" />
                          <span className="align-middle d-sm-inline-block d-none">
                            Previous
                          </span>
                        </Button>
                        <Button
                          type={"button"}
                          className="btn btn-primary btn-next"
                          onClick={handleSubmit2(handleNext2)}
                        >
                          {" "}
                          <span className="align-middle d-sm-inline-block d-none me-sm-1 me-0">
                            Next
                          </span>
                          <IconArrowRight />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div id="test-l-3" className="content">
                    <div className="content-header">
                      <h3 className="mb-1">Select Plan</h3>
                      <p>Select plan as per your requirement</p>
                    </div>
                    {plans.length > 0 && (
                      <div className="row gap-md-0 gap-3 my-4">
                        {plans.map((item, index) => (
                          <div key={index} className="col-md" onClick={() => setSelectedPlan(item?.id)}>
                            <div
                              className={`form-check custom-option custom-option-icon ${selectedPlan === item?.id && "active-plan"
                                }`}
                            >
                              <label
                                className="form-check-label custom-option-content"
                                for="basicOption"
                              >
                                <span className="custom-option-body">
                                  <span className="custom-option-title fs-4 mb-1">
                                    {item?.name}
                                  </span>
                                  <small className="fs-6">
                                    {item?.features?.subtitle}
                                  </small>
                                  <br />
                                  <small className="fs-6">
                                    Setup Fee +${item?.setupFees}
                                  </small>
                                  <span className="d-flex justify-content-center">
                                    <sup className="text-primary fs-6 lh-1 mt-3">
                                      $
                                    </sup>
                                    <span className="fw-medium fs-2 text-primary">
                                      {item?.price}
                                    </span>
                                    <sub className="lh-1 fs-6 mt-auto mb-2 text-muted">
                                      /month
                                    </sub>
                                  </span>
                                </span>
                                {/* <input
                                  name="customRadioIcon"
                                  className="form-check-input"
                                  type="radio"
                                  value=""
                                  id="basicOption"
                                  checked={selectedPlan === item?.id}
                                // onChange={() => setSelectedPlan(item?.id)}
                                /> */}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="content-header mb-4">
                      <div className="d-flex mb-3 coupon-field">
                        <input className="form-control" type="text" value={couponCode} placeholder="Coupon Code" onChange={e => setCouponCode(e.target.value)}  />
                        <button onClick={applyDiscount} className="btn btn-primary">Apply</button>
                      </div>
                      <h3 className="mb-1">Total Amount</h3>
                      {discount>0&&
                        <div className="d-flex justify-content-between align-items-center mt-3 pb-1">
                          <h5 className="mb-0">Sub Total</h5>
                          <h6 className="mb-0">
                            <sup className="text-primary fs-6 lh-1 mt-3">
                              $
                            </sup>
                            <span className="fw-medium fs-2 text-primary">
                              {parseFloat(plans.find(e => e.id === selectedPlan)?.price) + parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees)}
                            </span>
                          </h6>
                        </div>
                      }
                      {discount>0&&
                        <div className="d-flex justify-content-between align-items-center mt-3 pb-1">
                          <h5 className="mb-0">Discount</h5>
                          <h6 className="mb-0">
                            <sup className="text-danger fs-6 lh-1 mt-3">
                              - $
                            </sup>
                            <span className="fw-medium fs-2 text-danger">
                              {parseFloat(discount).toFixed(2)}
                            </span>
                          </h6>
                        </div>
                      }
                      {discount>0&&
                        <div className="d-flex justify-content-between align-items-center mt-3 pb-1">
                          <h5 className="mb-0">Total</h5>
                          <h6 className="mb-0">
                            <sup className="text-primary fs-6 lh-1 mt-3">
                              $
                            </sup>
                            <span className="fw-medium fs-2 text-primary">
                              {((parseFloat(plans.find(e => e.id === selectedPlan)?.price) + parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees))-discount)}
                            </span>
                          </h6>
                        </div>
                      }
                      {discount==0&&
                        <div className="d-flex justify-content-between align-items-center mt-3 pb-1">
                          <h5 className="mb-0">Total</h5>
                          <h6 className="mb-0">
                            <sup className="text-primary fs-6 lh-1 mt-3">
                              $
                            </sup>
                            <span className="fw-medium fs-2 text-primary">
                              {parseFloat(plans.find(e => e.id === selectedPlan)?.price) + parseFloat(plans.find(e => e.id === selectedPlan)?.setupFees)}
                            </span>
                          </h6>
                        </div>
                      }
                    </div>
                    <div className="content-header mb-4">
                      <h3 className="mb-1">Payment Information</h3>
                      <p>Enter your card information</p>
                    </div>
                    <Elements options={options} stripe={stripePromise}>
                      <StripePaymentForm
                        selectedPlan={selectedPlan}
                        email={step1Data?.email}
                        couponCode={couponCode}
                        handlePrevious={() => handlePrevious()}
                        submitRegister={() => submitRegister()}
                      />
                    </Elements>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
