import React, { useEffect, useState } from "react";
import "../assets/vendor/css/pages/front-page.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/UseContext";
import { ErrorToaster } from "./Toaster";
import SubscriptionServices from "../apis/subscription/SubscriptionServices";
import Spinner from "./spinner";
import ProfileServices from "../apis/profile/ProfileServices";

export default function Pricingpckg() {

  const { user } = useAuth();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState();

  const getSubscriptionPlans = async () => {
    setLoader(true)
    try {
      const { data } = await SubscriptionServices.getSubscriptionPlans()
      setPlans(data)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const getCurrentPlan = async () => {
    try {
      const { data } = await ProfileServices.getCurrentPlan()
      setCurrentPlan(data?.subscriptionDetails)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getSubscriptionPlans()
    getCurrentPlan()
  }, []);

  return (
    <>
      <h2 className="text-center mb-2 fw-bold">Pricing Plans</h2>
      <p className="text-center">
        Get started with us - it's perfect for individuals and teams. Choose a
        subscription plan that meets your needs.
      </p>
      {/* <div className="d-flex align-items-center justify-content-center flex-wrap gap-2 pb-5 pt-3 mb-0 mb-md-4">
        <label
          className="switch switch-primary ms-3 ms-sm-0 mt-2"
          onClick={handleToggle}
        >
          <span className="switch-label">Monthly</span>
          <input
            type="checkbox"
            className="switch-input price-duration-toggler"
            checked={isChecked}
            onChange={handleToggle}
          />
          <span className="switch-toggle-slider">
            <span className="switch-on"></span>
            <span className="switch-off"></span>
          </span>
          <span className="switch-label">Annual</span>
        </label>
        <div className="mt-n5 ms-n5 d-none d-sm-block">
          <IconCornerLeftDown />
          <span className="badge badge-sm bg-label-primary">
            Save up to 10%
          </span>
        </div>
      </div> */}

      {loader ? (
        <Spinner size='large' />
      ) : (
        <div className="row mx-0 gy-3 px-lg-5">

          {plans?.map((item, index) => (
            <div key={index} className="col-lg mb-md-0 mb-4">
              <div className="card border rounded shadow-none">
                <div className="card-body">
                  {/* <div className="my-3 pt-2 text-center">
                    {index === 0 &&
                      <img src={img2} alt="Basic Image" height="140" />
                    }
                    {index === 1 &&
                      <img src={img1} alt="Basic Image" height="140" />
                    }
                    {index === 2 &&
                      <img src={img3} alt="Basic Image" height="140" />
                    }
                  </div> */}
                  <h3 className="card-title text-center text-capitalize mb-1">
                    {item?.name}
                  </h3>
                  <p className="text-center">{item?.features?.subtitle}</p>
                  <div className="text-center">
                    <div className="d-flex justify-content-center">
                      <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">
                        $
                      </sup>
                      <h1 className="display-4 mb-0 text-primary">{item?.price}</h1>
                      <sub className="h6 pricing-duration mt-auto mb-2 text-muted fw-normal">
                        {isChecked ? '/year' : '/month'}
                      </sub>
                    </div>
                  </div>

                  {/* <ul className="ps-0 my-4 pt-2 circle-bullets">
                  {item?.features?.keyfeatures?.map((feature, i) => (
                    <li key={i} className="mb-2 d-flex align-items-center">
                      <i className="ti ti-point ti-lg"></i>{feature}
                    </li>
                  ))}
                </ul> */}
                  <div className="my-3 space-y-2">
                    {item?.features?.keyfeatures.map((item, index) => (
                      <div key={index} className="flex items-center">
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="text-green-400 opacity-75 w-5 h-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <div className="ml-2 text-sm text-gray-600">
                          {" "}
                          {item}{" "}
                        </div>
                      </div>
                    ))}
                  </div>

                  {item?.id === currentPlan?.id ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-label-success d-grid w-100"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Your Current Plan
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-label-primary d-grid w-100"
                      onClick={() => navigate('/payment', { state: item })}
                    >
                      Subscribe
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}

        </div>
      )}
    </>
  );
}
