import React, { useEffect, useState } from "react";
import NewSidebar from "./newsidebar";
import avatar1 from "../assets/img/avatars/1.png";
import { IconFileDescription, IconLock, IconRefreshDot, IconUpload, IconUser } from "@tabler/icons-react";
import Security from "./securityaccount";
import BillingPlan from "./billingsPlan";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Wrapper from "../wrapper/wrapper";
import ProfileServices from "../apis/profile/ProfileServices";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/UseContext";
import { baseUrl } from "../config/axios";
import { ErrorToaster, SuccessToaster } from "./Toaster";
import Spinner from "./spinner";
import CountryServices from "../apis/country/CountryServices";

export default function Account(props) {

  const { user, userLogin, userLogout } = useAuth();

  const [isChecked, setIsChecked] = useState(false);
  const [check, setCheck] = useState(1);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [loader, setLoader] = useState(false);

  const [currentPlan, setCurrentPlan] = useState();
  const [countries, setCountries] = useState([]);

  const handleShow = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you would like to deactivate your account?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { message } = await ProfileServices.deactivateAccount();
          Swal.fire(message, "", "success");
          userLogout()
        } catch (error) {
          Swal.fire("Error deactivating account", "", "error");
        }
      } else if (result.isDenied) {
        Swal.fire("Deactivation Cancelled!!", "", "error");
      }
    });
  };

  const handleKey = (val) => {
    setCheck(val);
  };

  const [profileDetail, setProfileDetail] = useState();

  const getProfileDetail = async () => {
    setLoader(true)
    try {
      const { data } = await ProfileServices.getProfileDetails()
      setProfileDetail(data)
      setValue('name', data?.name)
      setValue('email', data?.email)
      setValue('mobile', data?.mobile)
      setValue('businessName', data?.businessName)
      setValue('officeContact', data?.officeContact)
      setValue('address', data?.address)
      setValue('country', data?.country)
      setValue('state', data?.state)
      setValue('city', data?.city)
      setValue('zipCode', data?.zipCode)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const updateProfile = async (formData) => {
    try {
      const profileData = { ...formData }
      let obj = {
        email: profileDetail?.email,
        data: profileData
      }
      const { token } = await ProfileServices.updateProfile(obj)
      SuccessToaster("Profile Updated Successfully")
      let profile = { ...formData, profilePic: profileDetail?.profilePic, projectId: profileDetail?.projectId, token: token, }
      userLogin(profile)
      setProfileDetail(profile)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const uploadProfilePicture = async (e) => {
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { message, uploadedUrl } = await ProfileServices.uploadProfilePicture(formData);
      SuccessToaster(message)
      let profile = { ...profileDetail, token: user?.token, profilePic: uploadedUrl }
      userLogin(profile)
      setProfileDetail(profile)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const getCurrentPlan = async () => {
    try {
      const { data } = await ProfileServices.getCurrentPlan()
      setCurrentPlan(data?.subscriptionDetails)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const getCountries = async () => {
    try {
      const { data } = await CountryServices.getCountries()
      setCountries(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getCountries()
    getCurrentPlan()
    getProfileDetail()
  }, []);

  return (
    <Wrapper>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="py-3 mb-4 fw-bold">
            <span className="text-muted ">Account Settings /</span>{" "}

            {check === 1
              ? "Account"
              : check === 2
                ? "Security"
                : "Billings & Plans"}
          </h4>

          {loader ? (
            <Spinner size='large' />
          ) : (
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-pills flex-column flex-md-row mb-4">
                  <li className="nav-item " onClick={() => handleKey(1)}>
                    <a className={`nav-link ${check === 1 && "active"}`} style={{ cursor: "pointer" }}>
                      <IconUser className="me-1" /> Account
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => handleKey(2)}>
                    <a className={`nav-link ${check === 2 && "active"}`} style={{ cursor: "pointer" }}>
                      <IconLock className="me-1" /> Security
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => handleKey(3)}>
                    <a className={`nav-link ${check === 3 && "active"}`} style={{ cursor: "pointer" }}>
                      <IconFileDescription className="me-1" />
                      Billing & Plans
                    </a>
                  </li>
                </ul>

                {check == 1 && (
                  <>
                    <div className="card mb-4">
                      <h5 className="card-header">Profile Details</h5>
                      <div className="card-body">
                        <div className="d-flex align-items-start align-items-sm-center gap-4">
                          <img
                            src={profileDetail?.profilePic ? baseUrl + profileDetail?.profilePic : avatar1}
                            alt="user-avatar"
                            className="d-block w-px-100 h-px-100 rounded"
                            id="uploadedAvatar"
                          />
                          <div className="button-wrapper">
                            <label
                              for="upload"
                              className="btn btn-primary me-2 mb-3"
                              tabindex="0"
                            >
                              <span className="d-none d-sm-block">
                                Upload new photo
                              </span>
                              <IconUpload className="d-block d-sm-none" size={16} />
                              <input
                                type="file"
                                id="upload"
                                onChange={uploadProfilePicture}
                                className="account-file-input"
                                hidden
                                accept="image/png, image/jpeg"
                              />
                            </label>
                            {/* <button
                            type="button"
                            onClick={resetimage}
                            className="btn btn-label-secondary account-image-reset mb-3"
                          >
                            <IconRefreshDot className="d-block d-sm-none" size={16} />
                            <span className="d-none d-sm-block">Reset</span>
                          </button> */}

                            <div className="text-muted">
                              Allowed JPG, GIF or PNG. Max size of 800K
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-0" />
                      <div className="card-body">
                        <form
                          id="formAccountSettings"
                          method="POST"
                          onsubmit="return false"
                        >
                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label for="name" className="form-label">
                                Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                                autofocus
                                {...register('name', {
                                  required: 'Please enter name'
                                })}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label for="email" className="form-label">
                                E-mail
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                id="email"
                                name="email"
                                placeholder="email@example.com"
                                {...register('email', {
                                  required: 'Please enter email address'
                                })}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label" for="phoneNumber">
                                Mobile
                              </label>
                              <div className="input-group input-group-merge">
                                {/* <span className="input-group-text">US (+1)</span> */}
                                <input
                                  type="text"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  className="form-control"
                                  placeholder="202 555 0111"
                                  {...register('mobile')}
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-md-6">
                              <label for="business" className="form-label">
                                Business Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="business"
                                name="business"
                                placeholder="Business Name"
                                {...register('businessName', {
                                  required: 'Please enter business name'
                                })}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label" for="phoneNumber">
                                Office Contact
                              </label>
                              <div className="input-group input-group-merge">
                                {/* <span className="input-group-text">US (+1)</span> */}
                                <input
                                  type="text"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  className="form-control"
                                  placeholder="202 555 0111"
                                  {...register('officeContact')}
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-md-6">
                              <label for="address" className="form-label">
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                placeholder="Address"
                                {...register('address')}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label" for="country">
                                Country
                              </label>
                              <select
                                id="country"
                                className="select2 form-select"
                                data-allow-clear="true"
                                {...register('country')}
                              >
                                <option value="">Select Country</option>
                                {countries?.map((item, index) => (
                                  <option key={index} value={item?.name}>{item?.name}</option>
                                ))}
                              </select>
                            </div>
                            <div className="mb-3 col-md-6">
                              <label for="state" className="form-label">
                                State
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                id="state"
                                name="state"
                                placeholder="state"
                                {...register('state')}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label for="city" className="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                placeholder="city"
                                {...register('city')}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label for="zipCode" className="form-label">
                                Zip Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="zipCode"
                                name="zipCode"
                                placeholder="231465"
                                maxlength="6"
                                {...register('zipCode')}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              onClick={handleSubmit(updateProfile)}
                              className="btn btn-primary me-2"
                            >
                              Save changes
                            </button>
                            <button
                              type="reset"
                              className="btn btn-label-secondary"
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card">
                      <h5 className="card-header">Delete Account</h5>
                      <div className="card-body">
                        <div className="mb-3 col-12 mb-0">
                          <div className="alert alert-danger">
                            <h5 className="alert-heading mb-1">
                              Are you sure you want to delete your account?
                            </h5>
                            <p className="mb-0">
                              Once you delete your account, there is no going
                              back. Please be certain.
                            </p>
                          </div>
                        </div>
                        <form onsubmit="return false">
                          <div className="form-check mb-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={isChecked}
                              onChange={(e) => setIsChecked(e.target.checked)}
                            />
                            <label className="form-check-label text-danger">
                              I confirm my account deactivation
                              {!isChecked ? (
                                <p className="text-danger">
                                  Please confirm you want to delete account
                                </p>
                              ) : null}
                            </label>
                          </div>

                          <button
                            onClick={(e) => handleShow(e)}
                            type="submit"
                            disabled={!isChecked}
                            className="btn btn-danger deactivate-account"
                          >
                            Deactivate Account
                          </button>
                        </form>
                      </div>
                    </div>
                  </>
                )}

                {check == 2 && <Security />}
                {check == 3 && <BillingPlan currentPlan={currentPlan} />}
              </div>
            </div>
          )}
        </div>

        <div className="content-backdrop fade"></div>
      </div>
    </Wrapper>
  );
}
