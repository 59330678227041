import React, { useEffect, useState } from "react";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/css/data.scss";
import {
  IconSitemap,
  IconDatabaseImport,
  IconCopy,
  IconCloudUpload,
  IconUpload,
} from "@tabler/icons-react";
import { ErrorToaster, SuccessToaster } from "./Toaster";
import SitemapServices from "../apis/sitemap/SitemapServices";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/UseContext";
import moment from "moment";
import Spinner from "./spinner";

export default function Datatab() {

  const [check, setCheck] = useState(1);
  const [submenu, setSubMenu] = useState(1);
  const [submenuupload, setSubMenuUpload] = useState(1);

  const handleChange = (val) => {
    setCheck(val);
  };

  const { user } = useAuth();

  const { register, handleSubmit, resetField } = useForm();

  const [loading, setLoading] = useState(false);
  const [uploadingLoader, setUploadingLoader] = useState(false);

  const [sitemaps, setSitemaps] = useState([]);
  const [uploadedSitemaps, setUploadedSitemaps] = useState([]);

  const getSitemaps = async () => {
    try {
      let params = {
        projectId: user?.projectId,
      };
      const { data } = await SitemapServices.getSitemaps(params);
      setSitemaps(data?.sitemaps);
      setUploadedSitemaps(data?.uploads?.pages);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const deleteSitemap = async (id) => {
    setLoading(true)
    try {
      let params = {
        projectId: user?.projectId,
        sourceId: id
      }
      const { data } = await SitemapServices.deleteSitemap(params)
      getSitemaps()
      SuccessToaster('Sitemap delete successfully')
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  const addSitemap = async (formData) => {
    setLoading(true)
    try {
      let sitemapData = {
        sitemap_path: formData?.sitemap,
        file_data_retension: false,
        is_ocr_enabled: false,
        is_anonymized: false
      }
      let obj = {
        projectId: user?.projectId,
        data: sitemapData
      }
      await SitemapServices.addSitemap(obj)
      SuccessToaster('Sitemap add successfully')
      resetField('sitemap')
      getSitemaps()
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  const handleUploadSitemap = async (e) => {
    setUploadingLoader(true)
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file)
      formData.append('is_file_kept', 1)
      await SitemapServices.uploadSitemap(user?.projectId, formData)
      SuccessToaster('Sitemap upload successfully')
      getSitemaps()
    } catch (error) {
      console.log("🚀 ~ file: dataTab.js:93 ~ handleUploadSitemap ~ error:", error)
    } finally {
      setUploadingLoader(false)
    }
  }

  useEffect(() => {
    if (user?.configuredByAdmin) {
      getSitemaps()
    }
  }, []);

  return (
    <>
      <div className="settings-body mt-6 shadow-sm shadow-black rounded bg-white transition">
        <div className="setting-header border-b p-4">
          <span className="h3 fw-bold">Data</span>
        </div>
        <div className="setting-content">
          {user?.configuredByAdmin ? (
            <div className="row justify-content-around m-0">
              <div className="col-12 col-xl-3 left_side px-4">
                <div
                  onClick={() => handleChange(1)}
                  className="row m-0 subtab d-flex flex-col gap-3 my-4 align-items-center active cursor-pointer"
                >
                  <div
                    className={` ${check == 1 ? "bg-primary text-white" : "bg_grey"
                      } subtab-icon shadow-sm shadow-black rounded p-2  col-2`}
                    style={{ width: 40, height: 40 }}
                  >
                    <IconSitemap />
                  </div>
                  <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-8">
                    <div className="subtab-title">
                      <span className="fw-bold">Sitemap</span>
                    </div>
                    <div className="subtab-description">
                      <p className="text-muted m-0">
                        Provide sitemap for chatbot
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => handleChange(2)}
                  className="row  m-0 subtab d-flex flex-col gap-3 my-4 align-items-center active cursor-pointer"
                >
                  <div
                    className={`${check == 2 ? "bg-primary text-white" : "bg_grey"
                      } subtab-icon shadow-sm shadow-black rounded p-2  col-2`}
                    style={{ width: 40, height: 40 }}
                  >
                    <IconDatabaseImport />
                  </div>
                  <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-8">
                    <div className="subtab-title">
                      <span className="fw-bold">Upload</span>
                    </div>
                    <div className="subtab-description">
                      <p className="text-muted m-0">Upload files for chatbot</p>
                    </div>
                  </div>
                </div>

                {/* third */}
                {/* <div
                            onClick={() => handleChange(3)}
                            className="row m-0 subtab d-flex flex-col gap-4 my-4 align-items-center active cursor-pointer"
                          >
                            <div
                              className={` ${
                                check == 3 ? "bg-primary text-white" : "bg_grey"
                              } subtab-icon shadow-sm shadow-black rounded p-2 col-2`}
                              style={{ width: 40, height: 40 }}
                            >
                              <IconBrandZapier />
                            </div>
                            <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-8">
                              <div className="subtab-title">
                                <span className="fw-bold">Zapier</span>
                              </div>
                              <div className="subtab-description">
                                <p className="text-muted m-0">
                                  Your zapier integrations
                                </p>
                              </div>
                            </div>
                          </div> */}
              </div>
              {check == 1 && (
                <div className="col-12 col-xl-9 right_side">
                  <div>
                    <button
                      type="button"
                      className={`${submenu == 1 ? "btn-primary" : ""} btn `}
                      onClick={() => setSubMenu(1)}
                    >
                      <IconCopy className="mx-2" />
                      Manage sitemaps
                    </button>
                    <button
                      type="button"
                      className={`${submenu == 2 ? "btn-primary" : ""} btn mx-2`}
                      onClick={() => setSubMenu(2)}
                    >
                      <IconCloudUpload className="mx-2" />
                      Add sitemap
                    </button>
                  </div>
                  <br />
                  {submenu == 1 && (
                    <>
                      {sitemaps?.length > 0 &&
                        <>
                          {sitemaps?.map((item, index) => (
                            <div key={index} className="mb-2">
                              <div className="d-flex justify-content-between">
                                <div className="fw-bold">Scope</div>
                                <div className="">Created at: {moment(item?.created_at).format('DD MMM YYYY')}</div>
                              </div>
                              <input
                                disabled
                                value={item?.settings?.sitemap_path}
                                class="form-control"
                                type="text"
                              // placeholder="https://www.ideadentistry.com/page-sitemap.xml"
                              ></input>
                              <br />
                              <div className="fw-bold">Crawl Settings</div>
                              <div>
                                Refers to the configuration that controls how the web
                                crawler scans your pages to gather data for the chatbot.
                              </div>
                              <br />
                              <div className="d-flex mb-2">
                                <div className="fw-bold d-flex align-items-center">
                                  Executive JavaScript
                                </div>

                                <div class="form-check form-switch d-flex align-items-center mx-4">
                                  <input
                                    disabled
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked={item?.settings?.executive_js}
                                    style={{
                                      width: "46px",
                                      height: "30px",
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="d-flex w-50">
                                <button disabled={loading} type="button" className="btn btn-primary">
                                  Save Changes
                                </button>
                                <button disabled={loading} type="button" className="btn btn-danger mx-2" onClick={() => deleteSitemap(item?.id)}>
                                  Delete Sitemap
                                </button>
                              </div>
                            </div>
                          ))}
                        </>
                      }
                    </>
                  )}
                  {submenu == 2 && (
                    <>
                      <div>Scope</div>
                      <input
                        class="form-control mb-2"
                        type="text"
                        placeholder="XML Sitemap"
                        {...register('sitemap', {
                          required: 'Please enter a sitemap path'
                        })}
                      ></input>
                      <div className="text-muted mb-2">
                        Add new XML sitemap just like you would in Google Search
                        Console. We will detect all the pages in it and rebuild
                        your custom GPT chat bot with these pages.
                      </div>
                      <button disabled={loading} type="button" className="btn btn-primary" onClick={handleSubmit(addSitemap)}>
                        Add Sitemap
                      </button>
                    </>
                  )}
                </div>
              )}
              {check == 2 && (
                <div className="col-12 col-xl-9 right_side">
                  <div>
                    <button
                      type="button"
                      className={`${submenuupload == 1 ? "btn-primary" : ""
                        } btn mx-2`}
                      onClick={() => setSubMenuUpload(1)}
                    >
                      <IconCopy className="mx-2" />
                      Manage files
                    </button>
                    <button
                      type="button"
                      className={`${submenuupload == 2 ? "btn-primary" : ""
                        } btn mx-2`}
                      onClick={() => setSubMenuUpload(2)}
                    >
                      <IconCloudUpload className="mx-2" />
                      Upload files
                    </button>
                  </div>
                  <br />
                  {submenuupload == 1 && (
                    <div className="fw-bold">
                      <h4>Uploaded Files</h4>
                      <div>
                        {uploadedSitemaps?.length > 0 &&
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">FILE</th>
                                <th scope="col">LAST UPDATED</th>
                                <th scope="col">KEPT FOR VIEWING</th>
                                <th scope="col">ACTIONS</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uploadedSitemaps.map((item, index) => (
                                <tr key={index}>
                                  <td>{`${item?.filename} (${item?.filesize} Bytes)`}</td>
                                  <td>{moment(item?.updated_at).format('DD MMM YYYY')}</td>
                                  <td>{item?.is_file_kept ? 'Yes' : 'No'}</td>
                                  <td>
                                    <button disabled={loading} type="button" className="btn btn-danger mx-2" onClick={() => deleteSitemap(item?.id)}>
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        }
                      </div>
                    </div>
                  )}
                  {submenuupload == 2 && (
                    <div>
                      <h5>Documents</h5>
                      {uploadingLoader ? (
                        <Spinner size='large' />
                      ) : (
                        <div className="cursor-pointer dropzone text-center mb-3 dz-clickable position-relative">
                          <input
                            type="file"
                            onChange={handleUploadSitemap}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              minHeight: "150px",
                              height: "100%",
                              width: "100%",
                              opacity: 0,
                            }}
                          />
                          <div className="dz-default dz-message">
                            <button className="dz-button">
                              <div className="my-3">
                                <IconUpload />
                                <p className="font-weight-bold fw-bold">
                                  Drop files here or click to upload
                                </p>
                                <p>
                                  You can upload multiple files in any format
                                  (PDF/DOC/ZIP/ETC). Maximum file size is 100MB.
                                  Total batch size limit is 1GB. Max 50 files per
                                  batch. If you need to upload larger files, please
                                  split them into smaller parts or consider
                                  compressing them.
                                </p>
                              </div>
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="text-muted">
                        Your privacy and security are important to us. All
                        documents you upload are securely encrypted, processed and
                        deleted from our systems. By uploading your documents, you
                        agree to our privacy policy.
                      </div>
                      {/* <br /> */}
                      {/* <div className="fw-bold">Data Retention</div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        checked
                      />
                      <label
                        class="form-check-label d-flex"
                        for="exampleRadios1"
                      >
                        Keep For Viewing In Results
                      </label>
                    </div> */}
                      {/* <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios2"
                        value="option2"
                      />
                      <label
                        class="form-check-label d-flex"
                        for="exampleRadios2"
                      >
                        Delete Immediately After Processing
                      </label>
                    </div> */}

                      {/* <br />
                    <div className="fw-bold">Data Anonymizer</div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        checked
                      />
                      <label
                        class="form-check-label d-flex"
                        for="exampleRadios1"
                      >
                        OFF
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios2"
                        value="option2"
                        disabled
                      />
                      <label
                        class="form-check-label d-flex"
                        for="exampleRadios2"
                      >
                        ON
                        <div className="text-white mx-2 rounded bg-dark px-2">
                          PREMIUM PLANS ONLY
                        </div>
                      </label>
                    </div>

                    <br />
                    <div className="fw-bold">OCR</div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        checked
                      />
                      <label
                        class="form-check-label d-flex"
                        for="exampleRadios1"
                      >
                        OFF
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios2"
                        value="option2"
                        disabled
                      />
                      <label
                        class="form-check-label d-flex"
                        for="exampleRadios2"
                      >
                        ON
                        <div className="text-white mx-2 rounded bg-dark px-2">
                          PREMIUM PLANS ONLY
                        </div>
                      </label>
                    </div>

                    <br />
                    <button type="button" className="btn btn-primary mt-3">
                      Add Files
                    </button> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <h5 className="p-4 mb-4 fw-bold">Sorry, Chatbot is currently InActive.</h5>
          )}
        </div>
      </div>
    </>
  );
}
