import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "../assets/vendor/css/pages/front-page.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  IconCreditCard,
  IconPackage,
  IconSettings,
  IconShoppingCart,
  IconUser,
} from "@tabler/icons-react";
import Wrapper from "../wrapper/wrapper";
import { useNavigate } from "react-router-dom";
import avatar1 from "../assets/img/avatars/1.png";
import DashboardServices from "../apis/dashboard/DashboardServices";
import { useAuth } from "../context/UseContext";
import ProfileServices from "../apis/profile/ProfileServices";
import { baseUrl } from "../config/axios";
import { ErrorToaster } from "../components/Toaster";

export default function Dashboard(props) {

  const tooltipone = (
    <Tooltip id="tooltipfour">
      Project Settings
    </Tooltip>
  );

  const navigate = useNavigate();

  const handleMove = () => {
    navigate('/settings')
  }

  const [queryUsage, setQueryUsage] = useState([100, 0]);

  const state = {
    options: {
      dropShadow: {
        enabled: false,
      },
      chart: {
        type: "donut",
      },
      colors:['#61d9ca', '#F44336'],
      fill: {
        colors: ['#61d9ca', '#F44336']
      },
      markers: {
        colors: ['#61d9ca', '#F44336']
      },
      dataLabels: {
        style: {
          colors: ['#ffffff', '#ffffff']
        }
      },

      labels: ["Query Remaining", "Query Spent"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: "100%"
          },
          legend: {
            show: 'true'
          }
        }
      }],
      legend: {
        show: false,
      },
    },
  };

  const [stats, setStats] = useState();
  const { user, userLogin } = useAuth();

  const getDashboardStats = async () => {
    try {
      let params = {
        projectId: user?.projectId
      }
      const { data } = await DashboardServices.getDashboardStats(params)
      setStats(data)
      const totalQuery = data?.total_queries ? data?.total_queries : 0
      // setQueryUsage([20, 100])
      setQueryUsage([(data?.planQueryCount-totalQuery), totalQuery])
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const getProfileDetail = async () => {
    try {
      const { data } = await ProfileServices.getProfileDetails()
      const updateUserData = { ...data, token: user?.token }
      userLogin(updateUserData)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    if (user?.configuredByAdmin) {
      getDashboardStats()
    }
    getProfileDetail()
    document.title = "Kriss AI - Home Page";
  }, []);

  return (
    <Wrapper>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="py-3 mb-4 fw-bold">{props.name}</h4>
          {user?.configuredByAdmin ? (
            <>
              <div className="row">
                <div className="col-lg-12 col-xxl-12 mb-4 order-xxl-1">
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="card-title mb-0  flex-row d-flex align-items-center gap-3">
                        <div className="profile-image prof-new">
                          <img className="w-100 rounded-circle" src={user?.profilePic ? baseUrl + user?.profilePic : avatar1} />
                        </div>
                        <div>
                          <h5 className="m-0 me-2 p-0">{user?.name}</h5>
                          <small className="text-muted">
                            Total number of Queries {stats?.planQueryCount}
                          </small>
                        </div>
                      </div>
                      <div>
                        Last Updated 1 days ago
                        <OverlayTrigger placement="top" overlay={tooltipone}>
                          <IconSettings size={20} className="ml-2" style={{ cursor: 'pointer' }} onClick={handleMove} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-sm-6 mb-4">
                          <div className="card h-100">
                            <div className="card-body p-3">
                              <div className="card-icon">
                                <span className="badge bg-label-primary rounded-pill p-2">
                                  <IconUser size={16} />
                                </span>
                              </div>
                              <h5 className="card-title mb-0 py-2 mt-2">{stats?.pages_found ?? 0}</h5>
                              <p>Items Found</p>
                            </div>
                            <div id="subscriberGained"></div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mb-4">
                          <div className="card h-100">
                            <div className="card-body p-3">
                              <div className="card-icon">
                                <span className="badge bg-label-danger rounded-pill p-2">
                                  <IconShoppingCart size={16} />
                                </span>
                              </div>
                              <h5 className="card-title mb-0 py-2 mt-2">{stats?.pages_indexed ?? 0}</h5>
                              <p>Items Indexed</p>
                            </div>
                            <div id="quarterlySales"></div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mb-4">
                          <div className="card h-100">
                            <div className="card-body p-3">
                              <div className="card-icon">
                                <span className="badge bg-label-warning rounded-pill p-2">
                                  <IconPackage size={16} />
                                </span>
                              </div>
                              <h5 className="card-title mb-0 py-2 mt-2">{stats?.total_words_indexed ?? 0}</h5>
                              <p>Word Indexed</p>
                            </div>
                            <div id="orderReceived"></div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mb-4">
                          <div className="card h-100">
                            <div className="card-body p-3">
                              <div className="card-icon">
                                <span className="badge bg-label-success rounded-pill p-2">
                                  <IconCreditCard size={16} />
                                </span>
                              </div>
                              <h5 className="card-title mb-0 py-2 mt-2">{stats?.total_queries ?? 0}</h5>
                              <div>
                                <p>Queries this month</p>
                              </div>
                            </div>
                            <div id="revenueGenerated"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-xxl-12 mb-4 order-xxl-1">
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="card-title mb-0">
                        <h5 className="m-0 me-2">Query Usage</h5>
                        <small className="text-muted">
                          Total number of Queries {stats?.planQueryCount}
                        </small>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className=" container-fluid h-100">
                        <div className="justify-content-center align-items-center h-100">
                          <div className="col-xs-12 col-xl col-sm-12 text-center">
                            {queryUsage.length > 0 &&
                              <ReactApexChart
                                options={state?.options}
                                series={queryUsage}
                                type="donut"
                                width="100%"
                                height={400}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <h5 className="py-3 mb-4 fw-bold">Sorry, Chatbot is currently InActive.</h5>
          )}
        </div>

        <div className="content-backdrop fade"></div>
      </div>
    </Wrapper>
  );
}
